import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
    NotFoundComponent,
    UnauthenticatedComponent,
    SubscriptionInsufficientComponent,
} from "./shared/pages";
import { UnauthenticatedAccessGuard } from "./shared/guards/unauthenticated-access/unauthenticated-access.guard";
import { ManagerOnlyGuard } from "./shared/guards/unauthenticated-access/manager-only-guard";
import { AcknowledgedDisclaimerGuard } from "./shared/guards/acknowledged-disclaimer-guard";
import { UserListComponent } from "./pages/user-list/user-list.component";
import { HomeIndexComponent } from "./pages/home/home-index/home-index.component";
import { UserDetailComponent } from "./pages/user-detail/user-detail.component";
import { UserInviteComponent } from "./pages/user-invite/user-invite.component";
import { UserEditComponent } from "./pages/user-edit/user-edit.component";
import { LoginCallbackComponent } from "./pages/login-callback/login-callback.component";
import { HelpComponent } from "./pages/help/help.component";
import { CreateUserCallbackComponent } from "./pages/create-user-callback/create-user-callback.component";
import { AboutComponent } from "./pages/about/about.component";
import { DisclaimerComponent } from "./pages/disclaimer/disclaimer.component";
import { TractListComponent } from "./pages/tract-list/tract-list.component";
import { TractDetailComponent } from "./pages/tract-detail/tract-detail.component";
import { AccountListComponent } from "./pages/account-list/account-list.component";
import { AccountDetailComponent } from "./pages/account-detail/account-detail.component";
import { WellListComponent } from "./pages/well-list/well-list.component";
import { WellDetailComponent } from "./pages/well-detail/well-detail.component";
import { PoolListComponent } from "./pages/pool-list/pool-list.component";
import { PoolDetailComponent } from "./pages/pool-detail/pool-detail.component";
import { ManageBeehiveIntegrationComponent } from "./pages/manage-beehive-integration/manage-beehive-integration.component";
import { FlowMeterListComponent } from "./pages/flow-meter-list/flow-meter-list.component";
import { FlowMeterDetailComponent } from "./pages/flow-meter-detail/flow-meter-detail.component";
import { WithdrawalListComponent } from "./pages/withdrawal-list/withdrawal-list.component";
import { FieldDefinitionListComponent } from "./pages/field-definition-list/field-definition-list.component";
import { FieldDefinitionEditComponent } from "./pages/field-definition-edit/field-definition-edit.component";
import { AllocationPlanListComponent } from "./pages/allocation-plan-list/allocation-plan-list.component";
import { PoolingAgreementListComponent } from "./pages/pooling-agreement/pooling-agreement-list/pooling-agreement-list.component";
import { PoolingAgreementWorkflowComponent } from "./pages/pooling-agreement/pooling-agreement-workflow/pooling-agreement-workflow.component";
import { PoolingAgreementDetailComponent } from "./pages/pooling-agreement/pooling-agreement-detail/pooling-agreement-detail.component";
import { ReadOnlyGuard } from "./shared/guards/unauthenticated-access/read-only-guard";
import { TrainingComponent } from "./pages/training/training.component";
import { ReportsListComponent } from "./pages/reports-list/reports-list.component";
import { ReportTemplateEditComponent } from "./pages/report-template-edit/report-template-edit.component";
import { ReportTemplateDetailComponent } from "./pages/report-template-detail/report-template-detail.component";
import { PoolingReportsListComponent } from "./pages/pooling-reports-list/pooling-reports-list.component";
import { NitrogenReportsListComponent } from "./pages/nitrogen-reports-list/nitrogen-reports-list.component";
import { PhaseReportListComponent } from "./pages/phase-report-list/phase-report-list.component";
import { PhaseReportDetailComponent } from "./pages/phase-report-detail/phase-report-detail.component";

const routes: Routes = [
    {
        path: "labels-and-definitions/:id",
        component: FieldDefinitionEditComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "labels-and-definitions",
        component: FieldDefinitionListComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "allocation-plans",
        component: AllocationPlanListComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ReadOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "tracts",
        component: TractListComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ReadOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "tracts/:id",
        component: TractDetailComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ReadOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "wells",
        component: WellListComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ReadOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "wells/:id",
        component: WellDetailComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ReadOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "users",
        component: UserListComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "users/:id",
        component: UserDetailComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "users/:id/edit",
        component: UserEditComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "accounts",
        component: AccountListComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ReadOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "accounts/:id",
        component: AccountDetailComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ReadOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "pools",
        component: PoolListComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ReadOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "pools/:id",
        component: PoolDetailComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ReadOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "pooling-agreements",
        component: PoolingAgreementListComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ReadOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "pooling-agreements/create",
        component: PoolingAgreementWorkflowComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "pooling-agreements/edit/:poolingAgreementNumber",
        component: PoolingAgreementWorkflowComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "pooling-agreements/:poolingAgreementNumber",
        component: PoolingAgreementDetailComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ReadOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "withdrawals",
        component: WithdrawalListComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ReadOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "flow-meters",
        component: FlowMeterListComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ReadOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "flow-meters/:id",
        component: FlowMeterDetailComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ReadOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "invite-user/:userID",
        component: UserInviteComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "invite-user",
        component: UserInviteComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "manage-beehive-integration",
        component: ManageBeehiveIntegrationComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "reports",
        component: ReportsListComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "reports/new",
        component: ReportTemplateEditComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "reports/:id",
        component: ReportTemplateDetailComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "reports/:id/edit",
        component: ReportTemplateEditComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "pooling-reports",
        component: PoolingReportsListComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "nitrogen-reports",
        component: NitrogenReportsListComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "phase-reports",
        component: PhaseReportListComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    {
        path: "phase-reports/:id",
        component: PhaseReportDetailComponent,
        canActivate: [
            UnauthenticatedAccessGuard,
            ManagerOnlyGuard,
            AcknowledgedDisclaimerGuard,
        ],
    },
    { path: "", component: HomeIndexComponent },
    { path: "disclaimer", component: DisclaimerComponent },
    { path: "disclaimer/:forced", component: DisclaimerComponent },
    { path: "help", component: HelpComponent },
    { path: "training", component: TrainingComponent },
    { path: "platform-overview", component: AboutComponent },
    { path: "signin-oidc", component: LoginCallbackComponent },
    { path: "create-user-callback", component: CreateUserCallbackComponent },
    { path: "not-found", component: NotFoundComponent },
    {
        path: "subscription-insufficient",
        component: SubscriptionInsufficientComponent,
    },
    { path: "unauthenticated", component: UnauthenticatedComponent },
    { path: "**", component: NotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
