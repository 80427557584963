/**
 * Splash.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BoundingBoxDto } from '../model/bounding-box-dto';
import { TractWellDto } from '../model/tract-well-dto';
import { WellDto } from '../model/well-dto';
import { WithdrawalDto } from '../model/withdrawal-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class WellService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsBoundingBoxPut(requestBody?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<BoundingBoxDto>;
    public wellsBoundingBoxPut(requestBody?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BoundingBoxDto>>;
    public wellsBoundingBoxPut(requestBody?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BoundingBoxDto>>;
    public wellsBoundingBoxPut(requestBody?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<BoundingBoxDto>(`${this.basePath}/wells/boundingBox`,
            requestBody,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsBoundingBoxWithTractsPut(requestBody?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<BoundingBoxDto>;
    public wellsBoundingBoxWithTractsPut(requestBody?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BoundingBoxDto>>;
    public wellsBoundingBoxWithTractsPut(requestBody?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BoundingBoxDto>>;
    public wellsBoundingBoxWithTractsPut(requestBody?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<BoundingBoxDto>(`${this.basePath}/wells/boundingBoxWithTracts`,
            requestBody,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<WellDto>>;
    public wellsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellDto>>>;
    public wellsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellDto>>>;
    public wellsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellDto>>(`${this.basePath}/wells`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<WellDto>;
    public wellsWellIDGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellDto>>;
    public wellsWellIDGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellDto>>;
    public wellsWellIDGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellDto>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDTractsGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TractWellDto>>;
    public wellsWellIDTractsGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TractWellDto>>>;
    public wellsWellIDTractsGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TractWellDto>>>;
    public wellsWellIDTractsGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDTractsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TractWellDto>>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/tracts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellsWellIDWithdrawalsGet(wellID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WithdrawalDto>>;
    public wellsWellIDWithdrawalsGet(wellID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WithdrawalDto>>>;
    public wellsWellIDWithdrawalsGet(wellID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WithdrawalDto>>>;
    public wellsWellIDWithdrawalsGet(wellID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellID === null || wellID === undefined) {
            throw new Error('Required parameter wellID was null or undefined when calling wellsWellIDWithdrawalsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WithdrawalDto>>(`${this.basePath}/wells/${encodeURIComponent(String(wellID))}/withdrawals`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
