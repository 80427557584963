/**
 * Splash.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PoolingAgreementAttachmentUpsertDto } from '../model/pooling-agreement-attachment-upsert-dto';
import { PoolingAgreementDto } from '../model/pooling-agreement-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class PoolingAgreementService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param ids 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poolingAgreementsDelete(ids?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public poolingAgreementsDelete(ids?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public poolingAgreementsDelete(ids?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public poolingAgreementsDelete(ids?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ids !== undefined && ids !== null) {
            queryParameters = queryParameters.set('ids', <any>ids);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/pooling-agreements`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poolingAgreementsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<PoolingAgreementDto>>;
    public poolingAgreementsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PoolingAgreementDto>>>;
    public poolingAgreementsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PoolingAgreementDto>>>;
    public poolingAgreementsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PoolingAgreementDto>>(`${this.basePath}/pooling-agreements`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param poolingAgreementID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poolingAgreementsPoolingAgreementIDAttachmentsGet(poolingAgreementID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<PoolingAgreementAttachmentUpsertDto>>;
    public poolingAgreementsPoolingAgreementIDAttachmentsGet(poolingAgreementID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PoolingAgreementAttachmentUpsertDto>>>;
    public poolingAgreementsPoolingAgreementIDAttachmentsGet(poolingAgreementID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PoolingAgreementAttachmentUpsertDto>>>;
    public poolingAgreementsPoolingAgreementIDAttachmentsGet(poolingAgreementID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (poolingAgreementID === null || poolingAgreementID === undefined) {
            throw new Error('Required parameter poolingAgreementID was null or undefined when calling poolingAgreementsPoolingAgreementIDAttachmentsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PoolingAgreementAttachmentUpsertDto>>(`${this.basePath}/pooling-agreements/${encodeURIComponent(String(poolingAgreementID))}/attachments`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param poolingAgreementID 
     * @param poolingAgreementAttachmentUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poolingAgreementsPoolingAgreementIDAttachmentsPut(poolingAgreementID: number, poolingAgreementAttachmentUpsertDto?: Array<PoolingAgreementAttachmentUpsertDto>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public poolingAgreementsPoolingAgreementIDAttachmentsPut(poolingAgreementID: number, poolingAgreementAttachmentUpsertDto?: Array<PoolingAgreementAttachmentUpsertDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public poolingAgreementsPoolingAgreementIDAttachmentsPut(poolingAgreementID: number, poolingAgreementAttachmentUpsertDto?: Array<PoolingAgreementAttachmentUpsertDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public poolingAgreementsPoolingAgreementIDAttachmentsPut(poolingAgreementID: number, poolingAgreementAttachmentUpsertDto?: Array<PoolingAgreementAttachmentUpsertDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (poolingAgreementID === null || poolingAgreementID === undefined) {
            throw new Error('Required parameter poolingAgreementID was null or undefined when calling poolingAgreementsPoolingAgreementIDAttachmentsPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/pooling-agreements/${encodeURIComponent(String(poolingAgreementID))}/attachments`,
            poolingAgreementAttachmentUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param poolingAgreementID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poolingAgreementsPoolingAgreementIDDelete(poolingAgreementID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public poolingAgreementsPoolingAgreementIDDelete(poolingAgreementID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public poolingAgreementsPoolingAgreementIDDelete(poolingAgreementID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public poolingAgreementsPoolingAgreementIDDelete(poolingAgreementID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (poolingAgreementID === null || poolingAgreementID === undefined) {
            throw new Error('Required parameter poolingAgreementID was null or undefined when calling poolingAgreementsPoolingAgreementIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/pooling-agreements/${encodeURIComponent(String(poolingAgreementID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param poolingAgreementID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poolingAgreementsPoolingAgreementIDPublishPut(poolingAgreementID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public poolingAgreementsPoolingAgreementIDPublishPut(poolingAgreementID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public poolingAgreementsPoolingAgreementIDPublishPut(poolingAgreementID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public poolingAgreementsPoolingAgreementIDPublishPut(poolingAgreementID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (poolingAgreementID === null || poolingAgreementID === undefined) {
            throw new Error('Required parameter poolingAgreementID was null or undefined when calling poolingAgreementsPoolingAgreementIDPublishPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/pooling-agreements/${encodeURIComponent(String(poolingAgreementID))}/publish`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param poolingAgreementNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poolingAgreementsPoolingAgreementNumberGet(poolingAgreementNumber: string, observe?: 'body', reportProgress?: boolean): Observable<PoolingAgreementDto>;
    public poolingAgreementsPoolingAgreementNumberGet(poolingAgreementNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PoolingAgreementDto>>;
    public poolingAgreementsPoolingAgreementNumberGet(poolingAgreementNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PoolingAgreementDto>>;
    public poolingAgreementsPoolingAgreementNumberGet(poolingAgreementNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (poolingAgreementNumber === null || poolingAgreementNumber === undefined) {
            throw new Error('Required parameter poolingAgreementNumber was null or undefined when calling poolingAgreementsPoolingAgreementNumberGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PoolingAgreementDto>(`${this.basePath}/pooling-agreements/${encodeURIComponent(String(poolingAgreementNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param poolingAgreementDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public poolingAgreementsPost(poolingAgreementDto?: PoolingAgreementDto, observe?: 'body', reportProgress?: boolean): Observable<PoolingAgreementDto>;
    public poolingAgreementsPost(poolingAgreementDto?: PoolingAgreementDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PoolingAgreementDto>>;
    public poolingAgreementsPost(poolingAgreementDto?: PoolingAgreementDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PoolingAgreementDto>>;
    public poolingAgreementsPost(poolingAgreementDto?: PoolingAgreementDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PoolingAgreementDto>(`${this.basePath}/pooling-agreements`,
            poolingAgreementDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
