import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { CookieService } from "ngx-cookie-service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./shared/interceptors/auth-interceptor";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HomeIndexComponent } from "./pages/home/home-index/home-index.component";
import { UserListComponent } from "./pages/user-list/user-list.component";
import { RouterModule } from "@angular/router";
import { UserInviteComponent } from "./pages/user-invite/user-invite.component";
import { UserDetailComponent } from "./pages/user-detail/user-detail.component";
import { UserEditComponent } from "./pages/user-edit/user-edit.component";
import { TractDetailComponent } from "./pages/tract-detail/tract-detail.component";
import { AgGridModule } from "ag-grid-angular";
import { TractListComponent } from "./pages/tract-list/tract-list.component";
import { DecimalPipe, CurrencyPipe, DatePipe } from "@angular/common";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoginCallbackComponent } from "./pages/login-callback/login-callback.component";
import { HelpComponent } from "./pages/help/help.component";
import { SelectDropDownModule } from "ngx-select-dropdown";
import { AccountListComponent } from "./pages/account-list/account-list.component";
import { AccountDetailComponent } from "./pages/account-detail/account-detail.component";
import { CreateUserCallbackComponent } from "./pages/create-user-callback/create-user-callback.component";
import { AboutComponent } from "./pages/about/about.component";
import { DisclaimerComponent } from "./pages/disclaimer/disclaimer.component";
import { AppInitService } from "./app.init";
import { WellListComponent } from "./pages/well-list/well-list.component";
import { WellDetailComponent } from "./pages/well-detail/well-detail.component";
import { PoolListComponent } from "./pages/pool-list/pool-list.component";
import { PoolDetailComponent } from "./pages/pool-detail/pool-detail.component";
import { ManageBeehiveIntegrationComponent } from "./pages/manage-beehive-integration/manage-beehive-integration.component";
import { PoolDetailMapComponent } from "./pages/pool-detail-map/pool-detail-map.component";
import { FlowMeterListComponent } from "./pages/flow-meter-list/flow-meter-list.component";
import { FlowMeterDetailComponent } from "./pages/flow-meter-detail/flow-meter-detail.component";
import { WithdrawalListComponent } from "./pages/withdrawal-list/withdrawal-list.component";
import { FieldDefinitionListComponent } from "./pages/field-definition-list/field-definition-list.component";
import { FieldDefinitionEditComponent } from "./pages/field-definition-edit/field-definition-edit.component";
import { HttpErrorInterceptor } from "./shared/interceptors/httpErrorInterceptor";
import { AllocationPlanListComponent } from "./pages/allocation-plan-list/allocation-plan-list.component";
import { WaterUseByWellChartComponent } from "./components/water-use-by-well-chart/water-use-by-well-chart.component";
import { PoolWaterUseAndAllocationChartComponent } from "./components/pool-water-use-and-allocation-chart/pool-water-use-and-allocation-chart.component";
import { WaterBudgetOverviewComponent } from "./components/water-budget-overview/water-budget-overview.component";
import { PoolingAgreementWorkflowComponent } from "./pages/pooling-agreement/pooling-agreement-workflow/pooling-agreement-workflow.component";
import { PoolingAgreementWorkflowBasicsComponent } from "./pages/pooling-agreement/pooling-agreement-workflow-basics/pooling-agreement-workflow-basics.component";
import { PoolingAgreementListComponent } from "./pages/pooling-agreement/pooling-agreement-list/pooling-agreement-list.component";
import { PoolingAgreementWorkflowTractsComponent } from "./pages/pooling-agreement/pooling-agreement-workflow-tracts/pooling-agreement-workflow-tracts.component";
import { PoolingAgreementWorkflowReviewAndPublishComponent } from "./pages/pooling-agreement/pooling-agreement-workflow-review-and-publish/pooling-agreement-workflow-review-and-publish.component";
import { PoolingAgreementDetailComponent } from "./pages/pooling-agreement/pooling-agreement-detail/pooling-agreement-detail.component";
import { PoolingAgreementDetailTractMapComponent } from "./pages/pooling-agreement/pooling-agreement-detail-tract-map/pooling-agreement-detail-tract-map.component";
import { TrainingComponent } from "./pages/training/training.component";
import { CookieStorageService } from "./shared/services/cookies/cookie-storage.service";
import { ReportsListComponent } from "./pages/reports-list/reports-list.component";
import { ReportTemplateDetailComponent } from "./pages/report-template-detail/report-template-detail.component";
import { ReportTemplateEditComponent } from "./pages/report-template-edit/report-template-edit.component";
import { PoolingReportsListComponent } from "./pages/pooling-reports-list/pooling-reports-list.component";
import { NitrogenReportsListComponent } from "./pages/nitrogen-reports-list/nitrogen-reports-list.component";
import { PhaseReportDetailComponent } from "./pages/phase-report-detail/phase-report-detail.component";
import { PhaseReportListComponent } from "./pages/phase-report-list/phase-report-list.component";
import { ApiModule } from "./shared/generated/api.module";
import { Configuration } from "./shared/generated/configuration";
import { environment } from "src/environments/environment";

export function init_app(appLoadService: AppInitService) {
    return () => appLoadService.init();
}

@NgModule({
    declarations: [
        AppComponent,
        HomeIndexComponent,
        UserListComponent,
        UserInviteComponent,
        UserDetailComponent,
        UserEditComponent,
        TractDetailComponent,
        TractListComponent,
        LoginCallbackComponent,
        HelpComponent,
        AccountListComponent,
        AccountDetailComponent,
        CreateUserCallbackComponent,
        AboutComponent,
        DisclaimerComponent,
        WellListComponent,
        WellDetailComponent,
        PoolListComponent,
        PoolDetailComponent,
        ManageBeehiveIntegrationComponent,
        PoolDetailMapComponent,
        FlowMeterListComponent,
        FlowMeterDetailComponent,
        FieldDefinitionListComponent,
        FieldDefinitionEditComponent,
        WithdrawalListComponent,
        AllocationPlanListComponent,
        PoolWaterUseAndAllocationChartComponent,
        WaterUseByWellChartComponent,
        WaterBudgetOverviewComponent,
        PoolingAgreementWorkflowComponent,
        PoolingAgreementWorkflowBasicsComponent,
        PoolingAgreementListComponent,
        PoolingAgreementWorkflowTractsComponent,
        PoolingAgreementWorkflowReviewAndPublishComponent,
        PoolingAgreementDetailComponent,
        PoolingAgreementDetailTractMapComponent,
        TrainingComponent,
        ReportsListComponent,
        ReportTemplateDetailComponent,
        ReportTemplateEditComponent,
        PoolingReportsListComponent,
        NitrogenReportsListComponent,
        PhaseReportDetailComponent,
        PhaseReportListComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule,
        RouterModule,
        OAuthModule.forRoot(),
        SharedModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        AgGridModule,
        SelectDropDownModule,
        ApiModule.forRoot(() => {
            return new Configuration({
                basePath: `${environment.mainAppApiUrl}`,
            });
        }),
    ],
    providers: [
        CookieService,
        AppInitService,
        {
            provide: APP_INITIALIZER,
            useFactory: init_app,
            deps: [AppInitService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        DecimalPipe,
        CurrencyPipe,
        DatePipe,
        {
            provide: OAuthStorage,
            useClass: CookieStorageService,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
