/**
 * Splash.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FlowMeterDto } from '../model/flow-meter-dto';
import { WellSimpleDto } from '../model/well-simple-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class FlowMeterService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param flowMeterID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public flowMetersFlowMeterIDGet(flowMeterID: number, observe?: 'body', reportProgress?: boolean): Observable<FlowMeterDto>;
    public flowMetersFlowMeterIDGet(flowMeterID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FlowMeterDto>>;
    public flowMetersFlowMeterIDGet(flowMeterID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FlowMeterDto>>;
    public flowMetersFlowMeterIDGet(flowMeterID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (flowMeterID === null || flowMeterID === undefined) {
            throw new Error('Required parameter flowMeterID was null or undefined when calling flowMetersFlowMeterIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<FlowMeterDto>(`${this.basePath}/flow-meters/${encodeURIComponent(String(flowMeterID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param flowMeterID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public flowMetersFlowMeterIDWellsGet(flowMeterID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WellSimpleDto>>;
    public flowMetersFlowMeterIDWellsGet(flowMeterID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellSimpleDto>>>;
    public flowMetersFlowMeterIDWellsGet(flowMeterID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellSimpleDto>>>;
    public flowMetersFlowMeterIDWellsGet(flowMeterID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (flowMeterID === null || flowMeterID === undefined) {
            throw new Error('Required parameter flowMeterID was null or undefined when calling flowMetersFlowMeterIDWellsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellSimpleDto>>(`${this.basePath}/flow-meters/${encodeURIComponent(String(flowMeterID))}/wells`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public flowMetersGet(observe?: 'body', reportProgress?: boolean): Observable<Array<FlowMeterDto>>;
    public flowMetersGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FlowMeterDto>>>;
    public flowMetersGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FlowMeterDto>>>;
    public flowMetersGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<FlowMeterDto>>(`${this.basePath}/flow-meters`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
